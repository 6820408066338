@import "../../base/variables-colors";
@import "../../base/variables-typography";
@import "../../base/variables-breakpoints";
@import "../../base/variables";
@import "../../base/mixins";

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
  }
  
  .flickity-enabled:focus { outline: none; }
  
  .flickity-viewport {
    //overflow: hidden;
    overflow: visible;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
            tap-highlight-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19F;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: #333;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous { left: 10px; }
  .flickity-prev-next-button.next { right: 10px; }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -45px;
    left: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots { direction: rtl; }
  
  .flickity-page-dots .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px;
    background: #fff;
    cursor: pointer;
    transition: all 300ms;
  }
  
  .flickity-page-dots .dot.is-selected {
    background: $color-primary;
    transform: rotate(45deg);
  }



.Quote-Carousel {
  position: relative;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    padding-top: 170px;
    padding-bottom: 45px;
    @media (min-width: $md-and-up) {
      padding-top: 130px;    
      padding-bottom: 100px;
    }
    @media (max-width: $less-than-xs) {
      margin-bottom: $gutter*7;
    }
    &-Inner {
        position: relative;
        width: 100%;
        max-width: $width-md;
        margin-top: -0.5em;
        margin-bottom: 0;
        padding-left: $gutter;
        padding-right: $gutter;
        font-size: 0.8rem;
        text-align: center;
        &--xxl {
            max-width: 9000px;
            max-width: 100vw;
        }
        &--xl {
            max-width: $width-xl;
        }
        &--lg {
            max-width: $width-lg;
        }
        &--md {
            // note this is the default
        }
        &--sm {
            max-width: $width-sm;
        }
        &--xs {
            max-width: $width-xs;
        }
    }
    &-Item {
        width: 100%;
        margin: 0;
        opacity: 0;
        transition: opacity 800ms;
        &.is-selected {
            opacity: 1;
            transition-delay: 500ms;
        }
    }
}

.Bg-Mid {
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  &.rolled {
    overflow: visible;
  }
}
.Bg-Mid-Left1,
.Bg-Mid-Left2,
.Bg-Mid-Right0,
.Bg-Mid-Right1,
.Bg-Mid-Right2,
.Bg-Mid-Right3 {
  display: none;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  transform-origin: 120% 100%;
  transform: rotate(5deg) translate3d(0,0,0);
  transition: all 800ms;
  transition-delay: 500ms;
  @media (min-width: $md-and-up) {
      display: block;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
}
.Bg-Mid-Left1,
.Bg-Mid-Left2 {
  transform-origin: -20% 100%;
  transform: rotate(-5deg);
}
.Bg-Mid-Left1.rolled,
.Bg-Mid-Left2.rolled,
.Bg-Mid-Right0.rolled,
.Bg-Mid-Right1.rolled,
.Bg-Mid-Right2.rolled,
.Bg-Mid-Right3.rolled {
    opacity: 1;
    transform: rotate(0deg) translate3d(0,0,0);
}


.Bg-Mid-Left1 {
  left: 0;
  top: -51px;
  width: 221px;
  height: 314px;
    img {
        object-position: 100% 0;
    }
}
.Bg-Mid-Left2 {
  display: block;
  left: 80px;
  top: 20px;
  width: 270px;
  height: 205px;
  @media (max-width: $less-than-md) {
      top: 50px;
      left: calc( 50% - (270px/2) );
      transition-delay: 100ms;
  }
  @media (max-width: $less-than-sm) {
    top: 40px;
  }
}

.Bg-Mid-Right0 {
  right: 139px;
  top: 15px;
  width: 185px;
  height: 130px;
}
.Bg-Mid-Right1 {
    right: 0;
    top: -90px;
    width: 198px;
    height: 364px;
}
.Bg-Mid-Right2 {
    right: 0;
    top: 200px;
    width: 218px;
    height: 284px;
}
.Bg-Mid-Right3 {
    right: 0;
    top: 360px;
    width: 268px;
    height: 284px;
}